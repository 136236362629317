// @deprecated
export default function useCities({ key }: { key: string }) {
  const cities = ref()

  async function fetchCities(variables: any) {
    const { data } = await useAsyncData(getGqlFetchKey('UseCities', key), () =>
      GqlFetchCities(variables)
    )

    if (!data.value?.cities) {
      return
    }

    cities.value = data.value.cities.data
  }

  return {
    cities,
    fetchCities,
  }
}
