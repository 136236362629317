export default function useSearch({ key }: { key: string }) {
  const results = ref()
  const pending = ref(false)

  async function fetchResults(variables: {}) {
    const { data, execute } = await useAsyncData(
      getGqlFetchKey('UseSearch', key),
      () => GqlFetchSearch(variables),
      { immediate: false }
    )

    pending.value = true

    await execute()

    pending.value = false

    if (!data.value?.search) {
      return
    }

    results.value = data.value.search
  }

  return {
    pending,
    fetchResults,
    results,
  }
}
