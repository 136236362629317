<template>
  <Dropdown
    :button-text="triggerTitle"
    :button-theme="triggerTheme"
    :disabled="disabled"
    :open="isOpen"
    @open="isOpen = true"
    @close="isOpen = false"
  >
    <template #default>
      <FormKit
        :id="formId"
        v-model="formData"
        type="form"
        :actions="false"
        :classes="{
          form: 'px-4 md:max-h-64',
        }"
        @submit="persistInUrl"
      >
        <FormKit type="checkbox" :name="filterOption.key" :options="options" />
      </FormKit>
    </template>

    <template #footer>
      <Button
        :theme="BUTTON_THEME.SECONDARY"
        :size="BUTTON_SIZE.SM"
        :text="t('btn.delete')"
        @click="resetForm"
      />

      <Button
        :theme="BUTTON_THEME.PRIMARY"
        :size="BUTTON_SIZE.SM"
        :text="t('btn.apply')"
        @click="submitForm"
      />
    </template>
  </Dropdown>
</template>

<script lang="ts" setup>
import type { FilterOption } from '#gql/default'

interface Props {
  filterOption: FilterOption
  urlPrefix?: string
  preselected?: string[]
  disabled?: boolean
}

const { t } = useI18n()

const props = defineProps<Props>()

const {
  formId,
  formData,
  urlValues,
  persistInUrl,
  setData,
  resetForm,
  submitForm,
  isOpen,
  isSubmitted,
} = useFiltersForm({
  urlPrefix: props.urlPrefix,
  filterName: props.filterOption.key as UrlFilter,
})

function getSortOptions() {
  const selectedOptions = props.filterOption.values.filter(({ key }) =>
    (formData.value[props.filterOption.key] ?? []).includes(key)
  )
  const unselectedOptions = props.filterOption.values.filter(
    ({ key }) => !(formData.value[props.filterOption.key] ?? []).includes(key)
  )

  return (
    selectedOptions.length
      ? [...selectedOptions, ...unselectedOptions]
      : props.filterOption.values
  ).map(({ key, label }) => ({
    label,
    value: key,
  }))
}

// sort selected options to top
const options = ref(getSortOptions())

watch(isOpen, () => {
  options.value = [...getSortOptions()]
})

watch(urlValues, () => {
  setData(urlValues.value)
})

// Set inital data
if (props.preselected?.length || urlValues.value?.length) {
  setData(urlValues.value?.length ? urlValues.value : props.preselected)
}

const { triggerTheme, triggerTitle } = useTrigger(isSubmitted, urlValues)

function useTrigger(isSubmitted: Ref<boolean>, urlValues: Ref<string[]>) {
  const triggerTheme = ref()
  const triggerTitle = ref()

  function getTheme() {
    if (!isSubmitted.value) {
      return urlValues.value?.length || props.preselected?.length
        ? BUTTON_THEME.PRIMARY
        : BUTTON_THEME.SECONDARY
    }

    return urlValues.value?.length ?? 0 >= 1
      ? BUTTON_THEME.PRIMARY
      : BUTTON_THEME.SECONDARY
  }

  function getTitle() {
    const values = isSubmitted.value
      ? urlValues.value && urlValues.value.length > 0
        ? urlValues.value
        : props.preselected
      : urlValues.value && urlValues.value.length > 0
        ? urlValues.value
        : props.preselected

    if (
      isSubmitted.value &&
      (!urlValues.value || urlValues.value.length === 0)
    ) {
      return props.filterOption.label
    }

    const amount = values?.length ?? 0
    const firstFilter = values?.[0]

    if (!amount || !firstFilter) {
      // return filter category name if none is selected
      return props.filterOption.label
    }

    const selectedOption = options.value.find(
      ({ value }) => value === firstFilter
    )
    const selectedOptionLabel = selectedOption?.label || ''

    if (amount > 1) {
      return `${selectedOptionLabel} (+${amount - 1})`
    }

    return selectedOptionLabel
  }

  triggerTheme.value = getTheme()
  triggerTitle.value = getTitle()

  watch([isSubmitted, urlValues], () => {
    triggerTheme.value = getTheme()
    triggerTitle.value = getTitle()
    setData(urlValues.value)
  })

  return {
    triggerTheme,
    triggerTitle,
  }
}

defineOptions({
  name: 'FiltersGeneric',
})
</script>

<i18n>
de:
  btn:
    apply: "Anwenden"
    delete: "Löschen"
es:
  btn:
    apply: "Aplicar"
    delete: "Eliminar"
</i18n>
