<template>
  <Dropdown
    :open="isOpen"
    :button-text="triggerTitle"
    :button-theme="triggerTheme"
    @open="isOpen = true"
    @close="isOpen = false"
  >
    <template #default>
      <FormKit
        :id="formId"
        v-model="formData"
        type="form"
        :actions="false"
        :classes="{
          form: 'px-4 md:h-64 relative py-0.5',
        }"
        @submit="persistInUrl"
      >
        <FiltersGeoLocation
          :current-city="city ?? undefined"
          :use-geo-location="useGeoLocation"
          :url-prefix="urlPrefix"
          class="absolute right-4 top-1"
          @select="setCurrentCity"
        />
        <FormKit
          type="text"
          name="search"
          :placeholder="t('placeholder.search')"
          :delay="150"
          :classes="{}"
          @input="search"
          @keydown.enter="setCurrentCityWithoutSubmitting(results[0])"
        />

        <FormKit
          type="radio"
          :name="URL_FILTER.CITY"
          :options="cities"
          :classes="{
            options: 'mt-2',
            outer: '$reset w-full col-span-2 h-48',
            inner: '!hidden',
            option: 'hover:underline text-base',
            label: 'group-data-[checked=true]/form-option:text-primary',
            wrapper: '$reset w-full block py-1',
          }"
          @input="setCurrentCity"
        />
      </FormKit>
    </template>

    <template #footer>
      <Button
        :theme="BUTTON_THEME.SECONDARY"
        :size="BUTTON_SIZE.SM"
        :text="t('btn.delete')"
        @click="resetFilter"
      />
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import type { City } from '#gql/default'

interface Props {
  urlPrefix?: string
  useGeoLocation: boolean
  preselected?: string
  resetFilters?: UrlFilter[]
}

const props = defineProps<Props>()

const { t } = useI18n()

const {
  formId,
  formData,
  persistInUrl,
  submitForm,
  resetForm,
  isOpen,
  isEmpty,
  setData,
} = useFiltersForm({
  urlPrefix: props.urlPrefix,
  filterName: URL_FILTER.CITY,
  resetFilters:
    props.resetFilters ??
    Object.values(URL_FILTER).filter((key) => key !== URL_FILTER.CITY),
})

const { fetchResults, results } = useSearch({ key: formId })
const { city, resetCity } = await useCity({
  urlPrefix: props.urlPrefix,
  preselectedCitySlug: props.preselected,
})

const { triggerTheme, triggerTitle } = useTrigger()

const cities = computed(
  () =>
    results.value?.map(
      ({ urlSlug, name }: { urlSlug: string; name: string }) => ({
        label: name,
        value: urlSlug,
      })
    ) ?? []
)

async function setCurrentCity(city: City | string) {
  if (!city) {
    return
  }

  setData(typeof city === 'string' ? city : city.urlSlug)
  await nextTick()
  submitForm()
}

async function setCurrentCityWithoutSubmitting(city: City) {
  if (!city) {
    return
  }

  setData(city.urlSlug)
}

function resetFilter() {
  resetCity()
  resetForm()
}

async function search(query = '') {
  await fetchResults({ query, limit: 5, type: 'CITY' })
}

watch(
  city,
  () => {
    setData(city.value?.urlSlug ?? '')
  },
  { immediate: true }
)

defineOptions({
  name: 'FiltersCity',
})

watch(isOpen, async () => {
  if (isOpen.value) {
    await search()
  }
})

function useTrigger() {
  const triggerTitle = computed(() =>
    isEmpty.value ? t('btn.city') : city.value?.name ?? t('btn.city')
  )
  const triggerTheme = computed(() =>
    isEmpty.value ? BUTTON_THEME.SECONDARY : BUTTON_THEME.PRIMARY
  )

  return {
    triggerTitle,
    triggerTheme,
  }
}
</script>

<i18n>
de:
  btn:
    city: "Stadt"
    delete: "Löschen"
  placeholder:
    search: "Stadt/PLZ"
es:
  btn:
    city: "Ciudad"
    delete: "Eliminar"
  placeholder:
    search: "Ciudad/CP"
</i18n>
