<template>
  <div
    class="scrollbar-none group-data-[default-horizontal-spacing=true]:container-bleed overflow-x-auto group-data-[default-horizontal-spacing=true]:pl-5 sm:contents"
  >
    <ul class="flex sm:flex-wrap">
      <Suspense>
        <component :is="fetchComponent" :url-prefix :fetch-params :filters>
          <template #default="{ filterOptions, dates }">
            <li v-if="isSearchFilterVisible">
              <FiltersSearch
                :url-prefix
                :placeholder="searchPlaceholder"
                :preselected="getPreselectedValues(URL_FILTER.SEARCH)"
                class="mr-2 mt-2"
              />
            </li>

            <li v-if="isCityFilterVisible">
              <FiltersCity
                :url-prefix
                :use-geo-location="false"
                :preselected-city-slug="preselected?.city"
                class="mr-2 mt-2"
              />
            </li>

            <li
              v-for="filterOption in getAvailableOptions(filterOptions)"
              :key="filterOption.key"
              class="last:pr-3"
            >
              <FiltersDates
                v-if="filterOption.key === URL_FILTER.DATES"
                :url-prefix
                :disabled="
                  filterOptions.dates?.length === 1 || dates?.length === 1
                "
                :preselected="getPreselectedValues(URL_FILTER.DATES)"
                :filter-option
                class="mr-2 mt-2"
              />
              <FiltersGeneric
                v-else
                :key="filterOption.key"
                :url-prefix
                :filter-option
                :preselected="
                  getPreselectedValues(filterOption.key as UrlFilter)
                "
                class="mr-2 mt-2"
              />
            </li>
          </template>
        </component>
        <template #fallback>
          <li v-for="key in filters.length" :key>
            <FiltersSkeleton />
          </li>
        </template>
      </Suspense>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import type { FiltersDataProviderCinemasFetchParams } from '@base/components/filters/data-provider/cinemas.vue'
import type { FiltersDataProviderShowGroupsFetchParams } from '@base/components/filters/data-provider/show-groups.vue'
import type { FiltersDataProviderMoviesFetchParams } from '@base/components/filters/data-provider/movies.vue'
import type { FiltersDataProviderProgramFetchParams } from '@base/components/filters/data-provider/program.vue'
import type { FiltersDataProviderCinemaForMovieFetchParams } from '@base/components/filters/data-provider/cinemas-for-movie.vue'

import type { FilterOption } from '#gql/default'

export interface FiltersProps {
  urlPrefix?: string
  fetchComponent: any
  fetchParams:
    | FiltersDataProviderCinemasFetchParams
    | FiltersDataProviderShowGroupsFetchParams
    | FiltersDataProviderMoviesFetchParams
    | FiltersDataProviderProgramFetchParams
    | FiltersDataProviderCinemaForMovieFetchParams
  preselected?: {
    cinemaIds?: string[]
    auditoriums?: string[]
    contentRatings?: string[]
    languageFlags?: string[]
    technologyFlags?: string[]
    eventFlags?: string[]
    miscellaneousFlags?: string[]
    genres?: string[]
    periods?: string[]
    city?: string
    search?: string
    dates?: string[]
  }
  filters?: UrlFilter[]
  searchPlaceholder?: string
}

const props = withDefaults(defineProps<FiltersProps>(), {
  urlPrefix: '',
  filters: () => [],
  preselected: undefined,
  searchPlaceholder: undefined,
})

const { isCityFilterVisible, isSearchFilterVisible } = useFilterVisibility(
  props.filters
)

function getAvailableOptions(filterOptions: FilterOption[]) {
  return filterOptions.filter(
    (option: FilterOption) =>
      (props.filters?.includes(option.key as UrlFilter) &&
        option.key !== URL_FILTER.CINEMA_IDS) ||
      (option.key === URL_FILTER.CINEMA_IDS && option.values.length > 1)
  )
}

function getPreselectedValues(filterKey: string) {
  const filterMap: { [key: string]: string | string[] | undefined } = {
    [URL_FILTER.GENRES]: props.preselected?.genres,
    [URL_FILTER.PERIODS]: props.preselected?.periods,
    [URL_FILTER.CONTENT_RATINGS]: props.preselected?.contentRatings,
    [URL_FILTER.LANGUAGE]: props.preselected?.languageFlags,
    [URL_FILTER.TECHNOLOGY]: props.preselected?.technologyFlags,
    [URL_FILTER.EVENT]: props.preselected?.eventFlags,
    [URL_FILTER.MISCELLANEOUS]: props.preselected?.miscellaneousFlags,
    [URL_FILTER.CINEMA_IDS]: props.preselected?.cinemaIds,
    [URL_FILTER.AUDITORIUMS]: props.preselected?.auditoriums,
    [URL_FILTER.SEARCH]: props.preselected?.search,
    [URL_FILTER.DATES]: props.preselected?.dates,
  }

  return filterMap[filterKey] ?? undefined
}

function useFilterVisibility(filters: UrlFilter[]) {
  const isCityFilterVisible = computed(() => filters?.includes(URL_FILTER.CITY))
  const isSearchFilterVisible = computed(() =>
    filters?.includes(URL_FILTER.SEARCH)
  )

  return {
    isCityFilterVisible,
    isSearchFilterVisible,
  }
}

defineOptions({
  name: 'Filters',
})
</script>
